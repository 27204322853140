import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Layout } from "../components/layout"
import { ContactLinks } from "../components/contact-links"

const AboutPageTemplate = ({ data, location }) => {
  const {
    markdownRemark: { frontmatter, html },
  } = data

  return (
    <Layout
      location={location}
      title={frontmatter.title}
      className="page-about"
    >
      <div className="feature-image">
        <GatsbyImage
          style={{
            width: "382px",
            maxWidth: "100%",
            margin: "0 auto",
          }}
          image={frontmatter.featureimage.image.childImageSharp.gatsbyImageData}
          // fluid={frontmatter.featureimage.image.childImageSharp.fluid}
          layout="constrained"
          alt={frontmatter.featureimage.alt}
          title={frontmatter.featureimage.title}
        />
      </div>
      <div className="about-content">
        <h1>{frontmatter.title}</h1>
        <ContactLinks className="contact" exclude={["phone"]} />
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPageTemplate

export const pageQuery = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      id
      html
      frontmatter {
        featureimage {
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 382
                height: 537
                quality: 100
              )
            }
          }
          alt
          title
        }
        title
      }
    }
  }
`
